import "core-js/es6/map";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import "raf/polyfill";
import React from "react";
import { Route } from "react-router-dom";

import "./App.css";
import routes from "./routes.js";

import Header from "./components/header/header";
import Section from "./components/section/section";
import Contact from "./components/footer/contact";
import Footer from "./components/footer/footer";
import data from "./util/data";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#232323",
    },
    secondary: {
      main: "#c31924",
    },
  },
});

function App() {
  const {
    menuText,
    titleText,
    introductionText,
    informationText,
    contactText,
    footerText,
  } = data;
  return (
    <ThemeProvider theme={theme}>
      <Header menuText={menuText} />

      {routes.map((route) => {
        return (
          <Route
            key={route.key}
            path={route.path}
            exact={route.exact}
            render={() => {
              window.scrollTo(0, 0);
              return (
                <Section
                  title={titleText.title}
                  logo={titleText.logo}
                  image={titleText.titleImages[route.name]}
                  introductionText={introductionText[route.name]}
                  informationText={informationText[route.name]}
                />
              );
            }}
          />
        );
      })}

      <Contact contactText={contactText} />
      <Footer footerText={footerText} />
    </ThemeProvider>
  );
}

export default App;
