import React, { useState } from "react";

import LanguageBar from "./languagebar";
import LogoType from "./logotype.js";
import MobileMenu from "./mobilemenu.js";

import "./header.css";
import "./navbar.css";

function NavBar(props) {
  const [open, setOpen] = useState(false);

  const renderMenuItems = (menuItems) => {
    return menuItems.map((item, index) => {
      const Tag = item.tag;
      return (
        <Tag
          key={index}
          to={item.href}
          title={item.title}
          target={item.target}
          className={
            props.location.pathname === item.href ? "active-link" : null
          }
        >
          {item.name}
        </Tag>
      );
    });
  };

  return (
    <div id="navbar" className={"landMenu"}>
      <LanguageBar menuText={props.menuText} />
      <LogoType img={props.menuText.grimmeRed} isDrawerOpen={open} />
      <div className="menu">
        <ul className="menudesktop">
          <li className="items">
            {renderMenuItems(props.menuText.hrefs)}
            <a
              href="mailto:grips@grimme.de"
              title={props.menuText.email}
              target="_blank"
              rel="noopener noreferrer"
              className="emailLink"
            >
              <span className="material-icons"></span>
              {props.menuText.email}
            </a>
          </li>
        </ul>
      </div>
      <MobileMenu
        open={open}
        handleDrawer={() => setOpen(!open)}
        menuText={props.menuText}
      />
    </div>
  );
}

export default NavBar;
