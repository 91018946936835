import React from "react";

import RoutedNavBar from "./routed_navbar";

if (process.env.BROWSER) {
  require("./header.css");
}

function Header({ menuText }) {
  return (
    <header>
      <RoutedNavBar name="navbar" menuText={menuText} />
    </header>
  );
}

export default Header;
