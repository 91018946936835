import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { v4 as uuidv4 } from "uuid";

import menuBarBlack from "../images/burger_menu_icon_black.svg";
import closeBlack from "../images/burger_menu_icon_black_close.svg";
import grimmeRed from "../images/grimme-red-logo.svg";
import flagDe from "../images/de.png";
import flagEn from "../images/en.png";
import logo from "../images/grips-logo-gray.jpg";
import photo01 from "../images/photo01.jpg";
import photo02 from "../images/photo02.jpg";
import photo03 from "../images/photo03.jpg";
import photo04 from "../images/photo04.jpg";
import photo05 from "../images/photo05.jpg";
import photo06 from "../images/photo06.jpg";
import photo07 from "../images/photo07.jpg";
import photo10 from "../images/photo10.jpg";
import photo20 from "../images/photo20.jpg";
import photo21 from "../images/photo21.jpg";
import photo22 from "../images/photo22.jpg";
import photo23 from "../images/photo23.jpg";
import photo24 from "../images/photo24.jpg";
import photo30 from "../images/photo30.jpg";
import photo31 from "../images/photo31.jpg";
import photo32 from "../images/photo32.jpg";
import photo33 from "../images/photo33.jpg";
import photo34 from "../images/photo34.jpg";
import photo40 from "../images/photo40.jpg";
import photo41 from "../images/photo41.jpg";
import photo42 from "../images/photo42.jpg";
import photo43 from "../images/photo43.jpg";
import photo50 from "../images/photo50.jpg";
import photo51 from "../images/photo51.jpg";
import photo52 from "../images/photo52.jpg";
import photo60 from "../images/photo60.jpg";
import photo61 from "../images/photo61.jpg";
import photo62 from "../images/photo62.jpg";
import photo63 from "../images/photo63.jpg";
import photo70 from "../images/photo70.jpg";
import photo71 from "../images/photo71.jpg";
import photo72 from "../images/photo72.jpg";

import paths from "./paths";

const contactID = "contact";

const dataEn = {
  titleText: {
    title: "THE GRIMME PRODUCTION SYSTEM",
    titleImages: {
      main: photo10,
      shopFloorManagement: photo20,
      basicSkills: photo30,
      standardWork: photo40,
      valueStreamAlignment: photo50,
      qualityManagement: photo60,
      plantManagement: photo70,
    },
    logo: logo,
  },
  menuText: {
    currentLanguage: "English",
    chooseLanguage: "Please select your language",
    languageEn: "English",
    languageDe: "German",
    title: "The GRIMME Production System",
    email: "grips@grimme.com",
    flag: flagEn,
    flagDe: flagDe,
    flagEn: flagEn,
    menuBarBlack: menuBarBlack,
    closeBlack: closeBlack,
    grimmeRed: grimmeRed,
    hrefs: [
      {
        href: "",
        name: "Grips",
        title: "Grips",
        tag: Link,
        target: "_self",
      },
      {
        href: `#${contactID}`,
        name: "Contact",
        title: "Contact",
        tag: HashLink,
        target: "_self",
      },
    ],
  },
  introductionText: {
    main: {
      title: "GRIPS",
      text1:
        "In 2014, the GRIMME GRIPS production system was introduced at GRIMME Agricultural Machinery Factory in order to establish uniform corporate management with clearly defined processes and procedures. Maximum efficiency through the reduction of waste, an absolute focus on quality, a logical design of communication channels and continuous Working with improvement measures ensure competitiveness and long-term corporate success. ",
      text2:
        "The GRIMME production system consists of a total of six core elements, which are divided into two levels. The elements shop floor management and basic skills serve to stabilize the production environment on the first level. The element of standard work serves as a link to the second level. The principles of value stream alignment, quality management and plant management (TPM) shape continuous improvement (kaizen) on the second level of the GRIMME production system. ",
      image: photo01,
    },
    shopFloorManagement: {
      title: "SHOP FLOOR MANAGEMENT",
      text1:
        "The term shop floor management is made up of the words shop floor (workshop or hall floor) and management (management or control). The aim of this element is to eliminate any problems that arise directly at the point of origin, namely on the shop floor. Elementary for this is leadership directly at the place of value creation. ",
      text2:
        "So that problems that arise can be discovered, systematically recorded and solved by the responsible persons at an early stage, the four instruments of shop floor management have been established: regular communication, deviation visualization through 5S, problem-solving skills at the point of origin, and descriptions of the roles of workers and managers.",
      image: photo02,
    },
    basicSkills: {
      title: "BASIC SKILLS",
      text1:
        "The imparting of basic skills serves to stabilize everyday production steps and is carried out through training of employees. This gives them the basic knowledge and skills required for their tasks. This takes place in our training centers for assembly activities, welding activities and the use of hand-held measuring equipment, but also through Management training at all management levels. ",
      text2:
        "The employees are trained to work in a standardized manner and thereby receive a compact imparting of the basic knowledge for the trained work processes. In addition, the GRIMME production system GRIPS is taught to all employees in the training centers. Thanks to the skills and knowledge acquired, entry is particularly easy for new employees and facilitating their work in their area. ",
      image: photo03,
    },
    standardWork: {
      title: "STANDARD WORK",
      text1:
        "With the standard work, we at GRIMME ensure the reproducibility of work results and a consistently high product quality. To ensure this sustainability in production, a consistent approach and precise work instructions are necessary.",
      text2:
        "Documentation of the standards as well as constant adaptation and improvement of the standardized processes is particularly important for the principle of standard work. This takes place in a regular cycle in which the defined standard is checked and optimized again and again. Instruments of standard work at GRIMME are, for example General assembly instructions, defined work steps as well as technical specifications or procedural instructions. ",
      image: photo04,
    },
    valueStreamAlignment: {
      title: "VALUE FLOW ORIENTATION",
      text1:
        "The value stream orientation focuses on the purely value-adding activities in work processes that serve to directly increase customer benefit.",
      text2:
        "The aim of the value stream alignment is to concentrate each work step on the added value. This does not mean increasing the value adding activities, but reducing the non value adding and wasting activities in order to generate more capacities for value creation. At GRIMME this is achieved, for example, with instruments such as multi-stage picking or through the use of special analysis methods. ",
      image: photo05,
    },
    qualityManagement: {
      title: "QUALITY MANAGEMENT",
      text1:
        "The subject of quality management extends both to machine production and all upstream activities. Methods are used to remedy defects limit and thus always produce and ensure high quality in the manufacturing process. ",
      text2:
        "Quality assurance at GRIMME is an essential creator of customer satisfaction and uses various instruments such as the quality gates, the so-called master control boards and the quality room.",
      image: photo06,
    },
    plantManagement: {
      title: "PLANT MANAGEMENT",
      text1:
        "The focus of plant management is the establishment of the principle of autonomous maintenance combined with planned and condition-based maintenance.",
      text2:
        "This leads to fewer machine failures, which means that throughput times can be planned more reliably. As a result, maximum delivery reliability for machines and spare parts is ensured. Instruments of plant management at GRIMME are extensive documentation and detailed job descriptions.",
      image: photo07,
    },
  },
  informationText: {
    main: [
      {
        title: "Shop Floor Management",
        text:
          "Shop floor management creates transparency in the work areas and ensures a consistent communication structure.",
        align: "right",
        image: photo20,
        ID: uuidv4(),
        more: "Learn More",
        path: paths.shopFloorManagement,
      },
      {
        title: "Basic Skills",
        text:
          "The Basic Skills element ensures that all new and existing employees have the GRIMME-specific basic skills. The aim is to guarantee the high quality standards at all times.",
        align: "left",
        image: photo30,
        ID: uuidv4(),
        more: "Learn More",
        path: paths.basicSkills,
      },
      {
        title: "Standard Work",
        text:
          "The standardization of working methods and workplaces is the basis and the central starting point for optimization.",
        align: "right",
        image: photo40,
        ID: uuidv4(),
        more: "Learn More",
        path: paths.standardWork,
      },
      {
        title: "Value Stream Alignment",
        text:
          "A holistic value stream alignment includes stable work systems and waste-free work processes.",
        align: "left",
        image: photo50,
        ID: uuidv4(),
        more: "Learn More",
        path: paths.valueStreamAlignment,
      },
      {
        title: "Quality Management",
        text:
          "Quality management is essential for the quality of the intermediate and end products and serves as an essential creator of customer satisfaction.",
        align: "right",
        image: photo60,
        ID: uuidv4(),
        more: "Learn More",
        path: paths.qualityManagement,
      },
      {
        title: "Plant Management (TPM)",
        text:
          "With the help of plant management, the focus is on reducing downtimes and ensuring reliable availability of technical plants.",
        align: "left",
        image: photo70,
        ID: uuidv4(),
        more: "Learn More",
        path: paths.plantManagement,
      },
    ],
    shopFloorManagement: [
      {
        title: "Regular Communication",
        text:
          "The daily and above all structured exchange on the communication walls takes place in all production areas and on all hierarchical levels. Current key figures are discussed and necessary measures are derived if necessary.",
        align: "right",
        image: photo21,
        ID: uuidv4(),
      },
      {
        title: "5 S",
        text:
          "5S is a method for standardizing workplaces and working methods with the aim of being able to recognize deviations from the target state at a glance. After implementing the five steps of sorting, setting, cleaning, standardizing and self-discipline, it can be directly recognized whether the right work equipment are available in the right amount at the right place. ",
        align: "left",
        image: photo22,
        ID: uuidv4(),
      },
      {
        title: "Problem Solving Competency",
        text:
          "Problems that arise are solved on site in a systematic manner using a problem-solving flipchart. The trained processor is supported by explanations and assistance from the accompanying brochure.",
        align: "right",
        image: photo23,
        ID: uuidv4(),
      },
      {
        title: "Role Descriptions",
        text:
          "For the successful interaction of the work processes, it is important that there are clear roles with clearly formulated responsibilities and exact boundaries between the managers.",
        align: "left",
        image: photo24,
        ID: uuidv4(),
      },
    ],
    basicSkills: [
      {
        title: "Assembly Training Center",
        text:
          "In addition to the basic skills that are not machine or part-specific, the employees are taught elementary and job-specific skills. This ensures a consistent, high-quality assembly result.",
        align: "right",
        image: photo31,
        ID: uuidv4(),
      },
      {
        title: "Welding Training Center ",
        text:
          "In addition to their technical training, the employees are also trained in GRIMME-specific requirements. This includes, for example, the handling of welding devices, the idenuuidv4()tification of weld defects and various skill exercises. ",
        align: "left",
        image: photo32,
        ID: uuidv4(),
      },
      {
        title: "Handheld Measuring Equipment Training Center ",
        text:
          "In the day-to-day manufacturing processes, the articles are assessed using, among other things, the handheld measuring equipment. For this purpose, the employees are trained in the selection and correct use of the correct handheld measuring equipment. In addition, theoretical backgrounds and principles are imparted.",
        align: "right",
        image: photo33,
        ID: uuidv4(),
      },
      {
        title: "Management Training",
        text:
          "The training courses are an essential component of personnel development. Our managers are trained in the GRIMME production system GRIPS and receive further training measures in the area of ​​management skills.",
        align: "left",
        image: photo34,
        ID: uuidv4(),
      },
    ],
    standardWork: [
      {
        title: "General Assembly Instructions",
        text:
          "In the general assembly instructions, company-wide uuidv4()assembly standards are set to ensure constant quality at all times.",
        align: "right",
        image: photo41,
        ID: uuidv4(),
      },
      {
        title: "Specified Work Steps in the Assembly Lines",
        text:
          "Due to the defined work steps, all articles are assembled on the machines in the same order and with the same procedure. This way, work processes can be better planned.",
        align: "left",
        image: photo42,
        ID: uuidv4(),
      },
      {
        title: "Processes and Procedural Instructions",
        text:
          "Clear processes lead to a structured cooperation, so that all employees can do their work with orderly requirements.",
        align: "right",
        image: photo43,
        ID: uuidv4(),
      },
    ],
    valueStreamAlignment: [
      {
        title: "Material Staging Area (MBZ)",
        text:
          "In the MBZ, so-called set trolleys are assembled for the assembly line in a product- and workplace-specific manner, so that the right items are available to the employees at the right time and in the right quantity at the right place.",
        align: "right",
        image: photo51,
        ID: uuidv4(),
      },
      {
        title: "Analysis Methods",
        text:
          "Analysis methods such as the spaghetti diagram or the so-called Material and Information Flow Analysis (M&I) are used to idenuuidv4()tify waste.",
        align: "left",
        image: photo52,
        ID: uuidv4(),
      },
    ],
    qualityManagement: [
      {
        title: "Quality Gate",
        text:
          "The use of the quality gates serves to secure our machine quality by checking target specifications in the process as early as possible on the basis of series-specific plans.",
        align: "right",
        image: photo61,
        ID: uuidv4(),
      },
      {
        title: "Master Control Board",
        text:
          "The master control board serves as a visual aid fuuidv4()or the structured processing of quality defects and supports the essential phases of sustainable problem solving.",
        align: "left",
        image: photo62,
        ID: uuidv4(),
      },
      {
        title: "Quality Room",
        text:
          "The quality room is a method for improving product quality based on feedback from the field and the associated increase in customer satisfaction.",
        align: "right",
        image: photo63,
        ID: uuidv4(),
      },
    ],
    plantManagement: [
      {
        title: "Documentation",
        text:
          "With the help of maintenance overviews and checklists, the machine operator can carry out regular maintenance activities independently (autonomous maintenance)",
        align: "right",
        image: photo71,
        ID: uuidv4(),
      },
      {
        title: "Job Description Sheet",
        text:
          "Based on the illustrated job description sheet, the employee is guideduuidv4() step by step with explanations through the maintenance activities.",
        align: "left",
        image: photo72,
        ID: uuidv4(),
      },
    ],
  },
  contactText: {
    default: "Contact",
    success: "Thank you for your message",
    error: "Something went wrong",
    name: "Name",
    email: "E-mail",
    phone: "Phone Number",
    message: "Message",
    consent: "I agree with the data privacy agreement",
    consent1: "I agree with the ",
    consent2: "data privacy",
    consent3: " agreement",
    submit: "Submit",
    errors: {
      name: "Please enter your name",
      email: "Please enter a valid e-mail",
      phone: "Please enter a valid phone number",
      message: "Please write a message",
      consent: "Please confirm our data privacy agreement",
    },
    contactID: contactID,
  },
  footerText: {
    imprint: "Imprint",
    imprintHref: "https://www.grimme.com/de/page/impressum",
    data: "Data Protection",
    dataProtectionHref: "https://www.grimme.com/de/page/datenschutz",
  },
};

export default dataEn;
