import dataDe from "./data_de";
import dataEn from "./data_en";
import getCookie from "./get_cookie";
import setCookie from "./set_cookie";

function data() {
  const languageCookie = getCookie("language");
  if (languageCookie === "en") {
    setCookie("language", "en", 2050);
    return dataEn;
  } else {
    setCookie("language", "de", 2050);
    return dataDe;
  }
}

export default data();
