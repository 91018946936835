import { Drawer } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { Link } from "react-router-dom";

import "./header.css";
import LanguageBar from "./languagebar.js";

const useStyles = makeStyles((theme) => ({
  mobileMenuSpacing: {
    paddingTop: "8.5rem",

    [theme.breakpoints.down(768)]: {
      paddingTop: "7rem",
    },
    [theme.breakpoints.down(600)]: {
      paddingTop: "3rem",
    },
  },
}));

function MobileMenu({ open, handleDrawer, menuText }) {
  const classes = useStyles();

  return (
    <div>
      <div className="mobilemenu">
        <div className={"burgerMenuIconWrapper"}>
          {open && (
            <div className="LanguageBarWrapperSmall">
              <LanguageBar menuText={menuText} />
            </div>
          )}
          <div
            id="navIcon2"
            onClick={handleDrawer}
            className={open ? "open" : null}
          >
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
        </div>
      </div>
      <div>
        <Drawer
          style={{ zIndex: "8" }}
          open={open}
          onClose={handleDrawer}
          anchor="top"
        >
          <div onClick={handleDrawer}>
            <div className={classes.mobileMenuSpacing}></div>

            <ul className="mobilelist">
              {menuText.hrefs.map((item, index) => {
                const Tag = item.tag;
                return (
                  <li key={index}>
                    <Tag to={item.href} title={item.title}>
                      {item.name}
                    </Tag>
                  </li>
                );
              })}
              <li>
                <Link
                  to={`mailto:${menuText.email}`}
                  title={menuText.email}
                  className="mobilePhoneNr"
                >
                  {menuText.email}
                </Link>
              </li>
            </ul>
          </div>
        </Drawer>
      </div>
    </div>
  );
}

export default MobileMenu;
