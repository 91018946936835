import React from "react";

import Title from "./title";
import Intro from "./intro";
import Information from "./information";

function Section({ title, logo, image, introductionText, informationText }) {
  return (
    <>
      <Title title={title} logo={logo} image={image} />

      <Intro introductionText={introductionText} />

      {informationText.map((element) => {
        return <Information key={element.ID} informationText={element} />;
      })}
    </>
  );
}

export default Section;
