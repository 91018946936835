import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import validation from "../../util/contact_validation";

import "../../App.css";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  overallContainer: {
    minWidth: "300px",
    width: "100%",
  },

  formContainer: {
    width: "90%",
    [theme.breakpoints.up("xl")]: {
      maxWidth: "1440px",
    },
    margin: "auto",
    padding: "3rem 0rem 3rem 0rem",
    [theme.breakpoints.up("sm")]: {
      padding: "5rem 2rem 5rem 2rem",
    },
  },

  contactTitle: {
    marginBottom: "1rem",
  },

  fullGrid: {
    display: "block",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
  },

  contactButton: {
    display: "flex",
    transform: "skew(-24deg)",
    borderRadius: 0,
    padding: ".75rem 1.5rem",
    fontWeight: "bold",
    width: "200px",
    fontFamily: "Roboto, sans-serif",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
    },
  },

  contactButtonText: {
    transform: "skew(24deg)",
  },

  dataLink: {
    color: theme.palette.secondary.main,
  },
}));

const url =
  "https://xknzs9exz2.execute-api.us-east-1.amazonaws.com/dev/email/send";

const titleKey = {
  default: "default",
  success: "success",
  error: "error",
};

function Contact({ contactText }) {
  const classes = useStyles();

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [consent, setConsent] = useState(false);
  const [error, setError] = useState([]);
  const [titleIndex, setTitleIndex] = useState(titleKey.default);

  const titles = {
    default: contactText.default,
    success: contactText.success,
    error: contactText.error,
  };

  function postMessage(url) {
    let content = {
      email: email,
      name: name,
      content: message,
      phone: phone,
    };
    var req = new XMLHttpRequest();
    req.open("POST", url, true);
    req.setRequestHeader("Content-Type", "application/json");
    req.addEventListener("load", function () { });
    req.send(JSON.stringify(content));
  }

  function handleChange(event) {
    return event.target.value;
  }

  function handleSubmit(event) {
    event.preventDefault();

    // validation returns an array containing a boolean as the first argument and an object of the errors if they exist
    const validity = validation(name, phone, email, message, consent);

    if (validity[0]) {
      postMessage(url);
      setName("");
      setPhone("");
      setEmail("");
      setMessage("");
      setConsent(false);
      setTitleIndex(titleKey.success);

      setError({});
    } else {
      setError(validity[1]);
      setTitleIndex(titleKey.error);
    }
  }

  function InputField(input, inputString, setInput) {
    return (
      <TextField
        required
        id={inputString}
        name={inputString}
        label={contactText[inputString]}
        fullWidth
        autoComplete="off"
        onChange={(event) => setInput(handleChange(event))}
        value={input}
        error={!!error[inputString]}
        helperText={!!error[inputString] ? contactText.errors[inputString] : ""}
        multiline={inputString === "message" ? true : false}
        rows={inputString === "message" ? 7 : 1}
        variant={inputString === "message" ? "outlined" : "standard"}
      />
    );
  }

  return (
    <div className={classes.overallContainer} id={contactText.contactID}>
      <div className={classes.formContainer}>
        <h1 className={classes.contactTitle}>{titles[titleIndex]}</h1>
        <form noValidate onSubmit={handleSubmit}>
          <Grid className={classes.fullGrid} container spacing={2}>

            <Grid item xs={12} md={6} container spacing={1}>
              <Grid item xs={12}>
                {InputField(name, "name", setName)}
              </Grid>
              <Grid item xs={12}>
                {InputField(email, "email", setEmail)}
              </Grid>
              <Grid item xs={12}>
                {InputField(phone, "phone", setPhone)}
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              {InputField(message, "message", setMessage)}
            </Grid>

            <Grid item md={6}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      required
                      color={"secondary"}
                      name="privacy"
                      value="yes"
                      checked={consent}
                      onClick={() => {
                        setConsent(!consent);
                      }}
                    />
                  }
                  label={
                    <span>
                      {contactText.consent1}
                      <a
                        href="https://used.grimme.com/privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.dataLink}
                      >
                        {contactText.consent2}
                      </a>
                      {contactText.consent3}
                    </span>
                  }
                />
                <FormHelperText>
                  {!!error.consent ? contactText.errors.consent : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                className={classes.contactButton}
              >
                <span className={classes.contactButtonText}>
                  {contactText.submit}
                </span>
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
}

export default Contact;
