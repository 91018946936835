import { makeStyles } from "@material-ui/core/styles";
import React from "react";

import "../../App.css";

const useStyles = makeStyles((theme) => ({
  overallContainer: {
    maxWidth: "100%",
    minWidth: "300px",
    backgroundColor: "white",
  },

  introContainer: {
    maxWidth: "75%",
    margin: "30px auto 40px auto",
    [theme.breakpoints.up("xl")]: {
      maxWidth: "1440px",
    },
  },
  introTitle: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.1rem",
    },
  },

  introImageContainer: {
    textAlign: "center",
  },

  introImage: {
    padding: "20px",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      padding: "20px 0px 20px 0px",
    },
  },
}));

function Intro({ introductionText }) {
  const classes = useStyles();

  return (
    <div className={classes.overallContainer}>
      <div className={classes.introContainer}>
        <h1 className={classes.introTitle}>{introductionText.title}</h1>
        <p>{introductionText.text1}</p>
        <div className={classes.introImageContainer}>
          <img
            className={classes.introImage}
            src={introductionText.image}
            alt={introductionText.title}
          />
        </div>
        <p>{introductionText.text2}</p>
      </div>
    </div>
  );
}

export default Intro;
