import { v4 as uuidv4 } from "uuid";

import paths from "./util/paths";

const routes = [
  {
    name: "main",
    path: paths.main,
    exact: true,
    key: uuidv4(),
  },
  {
    name: "shopFloorManagement",
    path: paths.shopFloorManagement,
    key: uuidv4(),
  },
  {
    name: "basicSkills",
    path: paths.basicSkills,
    key: uuidv4(),
  },
  {
    name: "standardWork",
    path: paths.standardWork,
    key: uuidv4(),
  },
  {
    name: "valueStreamAlignment",
    path: paths.valueStreamAlignment,
    key: uuidv4(),
  },
  {
    name: "qualityManagement",
    path: paths.qualityManagement,
    key: uuidv4(),
  },
  {
    name: "plantManagement",
    path: paths.plantManagement,
    key: uuidv4(),
  },
];

export default routes;
