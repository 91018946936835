import React from "react";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  footer: {
    minWidth: "300px",
    padding: "1rem 0",
    backgroundColor: "#232323",
    textAlign: "center",
    color: "#666",
    fontSize: ".8rem",
  },

  footerContainer: {
    [theme.breakpoints.up(768)]: {
      justifyContent: "center",
      display: "flex",
    },
  },
  footerItem: {
    padding: "1rem 0",
    [theme.breakpoints.up(768)]: {
      marginRight: "3rem",
    },
  },

  footerLinks: {
    [theme.breakpoints.up(768)]: {
      marginLeft: "1rem",
      display: "flex",
    },
  },

  footerLink: {
    display: "block",
    padding: "1rem",
    color: "#666",
    textDecoration: "none",
    "&:hover": {
      color: "white",
    },
  },
}));

function Footer({ footerText }) {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container className={classes.footerContainer}>
        <div className={classes.footerItem}>
          © GRIMME Landmaschinenfabrik GmbH &amp; Co. KG 2020
        </div>
        <div className={classes.footerLinks}>
          <a
            className={classes.footerLink}
            href={footerText.imprintHref}
            target="_blank"
            rel="noopener noreferrer"
          >
            {footerText.imprint}
          </a>
          <a
            className={classes.footerLink}
            href={footerText.dataProtectionHref}
            target="_blank"
            rel="noopener noreferrer"
          >
            {footerText.data}
          </a>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
