import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";

import setCookie from "../../util/set_cookie";

const CssDialog = withStyles({
  root: {
    "& .MuiDialog-paper": {
      borderRadius: 0,
      margin: 0,
      paddingBottom: "1.5rem",
      backgroundColor: "rgba(0, 0, 0, .6)",
    },
    "& .MuiDialogTitle-root": {
      paddingTop: "1.5rem",
    },
    "& .MuiDialogContent-root": {
      paddingTop: 0,
      textAlign: "center",
    },
  },
})(Dialog);

const useStyles = makeStyles((theme) => ({
  flags: {
    display: "flex",
    gridTemplateColumns: "repeat(2, min-content)",
    rowGap: "1rem",
    overflow: "hidden",
    justifyContent: "center",
    [theme.breakpoints.up(600)]: {
      gridTemplateColumns: "repeat(4, minmax(0, min-content))",
    },
  },

  flagWrapper: {
    margin: 0,
  },

  flag: {
    width: "6.75rem",
    color: "white",
    textAlign: "center",
    cursor: "pointer",
  },

  flagImage: {
    marginTop: "0.5rem",
    width: "75px",
    height: "50px",
    boxShadow: "0px 0px 2px 1px #333",
  },
}));

function LanguageBar({ menuText }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const closeAndChange = (lang) => {
    setCookie("language", lang, 2050);

    if (openDialog) {
      setOpenDialog(false);
    }
    if (open) {
      setOpen(false);
    }
    window.location.reload(1);
  };

  return (
    <div className="LanguageBar">
      <div>
        <span className="grimme">{menuText.title}</span>
      </div>
      <div>
        <img
          src={menuText.flag}
          alt={menuText.currentLanguage}
          title={menuText.currentLanguage}
          onClick={() => setOpenDialog(true)}
        />
      </div>
      <CssDialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle className="dialogtitle">
          {menuText.chooseLanguage}
        </DialogTitle>
        <DialogContent>
          <div className={classes.flags}>
            <div className={classes.flagWrapper}>
              <div
                className={classes.flag}
                onClick={() => closeAndChange("de")}
              >
                <p>{menuText.languageDe}</p>
                <img
                  src={menuText.flagDe}
                  className={classes.flagImage}
                  alt="de"
                />
              </div>
            </div>

            <div className={classes.flagWrapper}>
              <div
                className={classes.flag}
                onClick={() => closeAndChange("en")}
              >
                <p>{menuText.languageEn}</p>
                <img
                  src={menuText.flagEn}
                  className={classes.flagImage}
                  alt="en"
                />
              </div>
            </div>
          </div>
        </DialogContent>
      </CssDialog>
    </div>
  );
}

export default LanguageBar;
