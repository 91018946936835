import { makeStyles } from "@material-ui/core/styles";
import React from "react";

import "../../App.css";

const sectionHeight = 550;

const useStyles = makeStyles((theme) => ({
  overallContainer: {
    maxWidth: "100%",
    minWidth: "300px",
    backgroundColor: "#212121",
  },

  titleContainer: {
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      backgroundColor: "#212121",
      textAlign: "right",
      maxWidth: 1920,
      margin: "auto",
      height: 550,
    },
  },

  logoContainer: {
    width: "60%",
    padding: "5%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  titleImage: {
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  titleText: {
    color: "white",
    fontSize: "2.7rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5rem",
    },
  },

  backgroundImage: {
    backgroundSize: "cover",
    height: sectionHeight,
    backgroundRepeat: "no-repeat",
    position: "Relative",
    [theme.breakpoints.down("sm")]: {
      height: sectionHeight / 2,
    },
  },

  backgroundImageBorder: {
    "&::after": {
      content: '""',
      width: 0,
      height: 0,
      borderRight: "0px solid transparent",
      borderLeft: "137.5px solid transparent",
      borderBottom: "550px solid #212121",
      position: "absolute",
      right: 0,
      bottom: 0,
      [theme.breakpoints.down("sm")]: {
        borderRight: 0,
        borderLeft: 0,
        borderBottom: 0,
      },
    },
  },

  gripsLogo: {
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
}));

function Title(props) {
  const classes = useStyles();
  return (
    <div className={classes.overallContainer}>
      <div className={classes.titleContainer}>
        <div className={classes.titleImage}>
          <div
            className={`${classes.backgroundImage} ${classes.backgroundImageBorder}`}
            style={{
              backgroundImage: `url(${props.image})`,
              backgroundPosition: `center`,
            }}
          />
        </div>
        <div className={classes.logoContainer}>
          <span>
            <img
              className={classes.gripsLogo}
              src={props.logo}
              alt={"Grips Logo"}
            />
          </span>
          <h1 className={classes.titleText}>{props.title}</h1>
        </div>
      </div>
    </div>
  );
}

export default Title;
