import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React from "react";
import { Link } from "react-router-dom";

import "../../App.css";

const desktopWidth = 1920;
const mobileWidth = 960;
const sectionHeight = 550;

const useStyles = makeStyles((theme) => ({
  overallContainer: {
    minWidth: "300px",
    width: "100%",
    backgroundColor: "#212121",
  },

  infoContainer: {
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      maxWidth: desktopWidth,
      display: "flex",
      margin: "auto",
      height: sectionHeight,
      textAlign: "left",
    },
  },

  imageContainer: {
    height: "100%",
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  textContainer: {
    padding: "7%",
    margin: "auto",
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  backgroundImage: {
    backgroundSize: "cover",
    height: sectionHeight,
    backgroundRepeat: "no-repeat",
    position: "Relative",
    "&::after": {
      content: '""',
      width: 0,
      height: 0,
      bottom: 0,
      position: "absolute",
    },
    [theme.breakpoints.down("sm")]: {
      height: sectionHeight / 2,
    },
  },

  leftBackgroundBorder: {
    "&::after": {
      borderLeft: "0px solid transparent",
      borderRight: `${sectionHeight / 4}px solid transparent`,
      borderTop: `${sectionHeight}px solid #212121`,
      left: 0,
      [theme.breakpoints.down("sm")]: {
        borderTop: 0,
      },
    },
  },

  rightBackgroundBorder: {
    "&::after": {
      borderRight: "0px solid transparent",
      borderLeft: `${sectionHeight / 4}px solid transparent`,
      borderBottom: `${sectionHeight}px solid #212121`,
      right: 0,
      [theme.breakpoints.down("sm")]: {
        borderBottom: 0,
      },
    },
  },

  noBackgroundBorder: {
    "&::after": {
      borderRight: "0px solid transparent",
      borderLeft: "137.5px solid transparent",
      borderBottom: 0,
      right: 0,
    },
  },

  buttonBox: {
    display: "flex",
    transform: "skew(-24deg)",
    borderRadius: 0,
    padding: ".75rem 1.5rem",
    fontWeight: "bold",
    width: "200px",
    fontFamily: "Roboto, sans-serif",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
    },
    backgroundColor: theme.palette.secondary.main,
    textDecoration: "none",
  },

  buttonText: {
    textTransform: "uppercase",
    color: "white",
    transform: "skew(24deg)",
    margin: "auto",
    fontSize: "14px",
  },
}));

function Information({ informationText }) {
  const matches = useMediaQuery(`(min-width:${mobileWidth}px)`);
  const classes = useStyles();

  const InfoImage = ({ borderAlign }) => (
    <div className={classes.imageContainer}>
      <div
        className={`${classes.backgroundImage} ${borderAlign}`}
        style={{
          backgroundImage: `url(${informationText.image})`,
          backgroundPosition: `center`,
        }}
      />
    </div>
  );

  const InfoText = () => (
    <div className={classes.textContainer}>
      <h2>{informationText.title}</h2>
      <h3>{informationText.text}</h3>
      {informationText.path ? (
        // <SkewedButton type="span" variant="contained" color="secondary" onClick={(e) => e.preventDefault()}>

        <Link
          to={`${informationText.path}`}
          title={informationText.title}
          target="_self"
          className={classes.buttonBox}
        >
          <span className={classes.buttonText}>{informationText.more}</span>
        </Link>
      ) : (
        // </SkewedButton>
        <></>
      )}
    </div>
  );

  return (
    <div className={classes.overallContainer}>
      <div className={classes.infoContainer}>
        {informationText.align === "right" || !matches ? (
          <>
            <InfoImage borderAlign={classes.rightBackgroundBorder} />
            <InfoText />
          </>
        ) : (
          <>
            <InfoText />
            <InfoImage borderAlign={classes.leftBackgroundBorder} />
          </>
        )}
      </div>
    </div>
  );
}

export default Information;
