import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { v4 as uuidv4 } from "uuid";

import menuBarBlack from "../images/burger_menu_icon_black.svg";
import closeBlack from "../images/burger_menu_icon_black_close.svg";
import grimmeRed from "../images/grimme-red-logo.svg";
import flagDe from "../images/de.png";
import flagEn from "../images/en.png";
import logo from "../images/grips-logo-gray.jpg";
import photo01 from "../images/photo01.jpg";
import photo02 from "../images/photo02.jpg";
import photo03 from "../images/photo03.jpg";
import photo04 from "../images/photo04.jpg";
import photo05 from "../images/photo05.jpg";
import photo06 from "../images/photo06.jpg";
import photo07 from "../images/photo07.jpg";
import photo10 from "../images/photo10.jpg";
import photo20 from "../images/photo20.jpg";
import photo21 from "../images/photo21.jpg";
import photo22 from "../images/photo22.jpg";
import photo23 from "../images/photo23.jpg";
import photo24 from "../images/photo24.jpg";
import photo30 from "../images/photo30.jpg";
import photo31 from "../images/photo31.jpg";
import photo32 from "../images/photo32.jpg";
import photo33 from "../images/photo33.jpg";
import photo34 from "../images/photo34.jpg";
import photo40 from "../images/photo40.jpg";
import photo41 from "../images/photo41.jpg";
import photo42 from "../images/photo42.jpg";
import photo43 from "../images/photo43.jpg";
import photo50 from "../images/photo50.jpg";
import photo51 from "../images/photo51.jpg";
import photo52 from "../images/photo52.jpg";
import photo60 from "../images/photo60.jpg";
import photo61 from "../images/photo61.jpg";
import photo62 from "../images/photo62.jpg";
import photo63 from "../images/photo63.jpg";
import photo70 from "../images/photo70.jpg";
import photo71 from "../images/photo71.jpg";
import photo72 from "../images/photo72.jpg";

import paths from "./paths";

const contactID = "contact";

const dataDE = {
  titleText: {
    title: "DAS GRIMME PRODUKTIONSSYSTEM",
    titleImages: {
      main: photo10,
      shopFloorManagement: photo20,
      basicSkills: photo30,
      standardWork: photo40,
      valueStreamAlignment: photo50,
      qualityManagement: photo60,
      plantManagement: photo70,
    },
    logo: logo,
  },
  menuText: {
    currentLanguage: "Deutsch",
    chooseLanguage: "Bitte wählen Sie Ihre Sprache",
    languageEn: "Englisch",
    languageDe: "Deutsch",
    title: "Das GRIMME Produktionssystem",
    email: "grips@grimme.com",
    flag: flagDe,
    flagDe: flagDe,
    flagEn: flagEn,
    menuBarBlack: menuBarBlack,
    closeBlack: closeBlack,
    grimmeRed: grimmeRed,
    hrefs: [
      {
        href: "",
        name: "Grips",
        title: "Grips",
        tag: Link,
        target: "_self",
      },
      {
        href: `#${contactID}`,
        name: "Kontakt",
        title: "Kontakt",
        tag: HashLink,
        target: "_self",
      },
    ],
  },
  introductionText: {
    main:{
      title: "GRIPS",
      text1:
        "Im Jahr 2014 wurde bei der GRIMME Landmaschinenfabrik das GRIMME Produktionssystem GRIPS eingeführt, um eine einheitliche Unternehmensführung mit eindeutig definierten Prozessen und Abläufen zu etablieren. Höchste Effizienz durch die Reduzierung von Verschwendungen, eine absolute Fokussierung auf Qualität, eine logische Gestaltung von Kommunikationswegen sowie die kontinuierliche Arbeit mit Verbesserungsmaßnahmen sichern die Wettbewerbsfähigkeit und den langfristigen Unternehmenserfolg.",
      text2:
        "Das GRIMME Produktionssystem besteht aus insgesamt sechs Kernelementen, die sich in zwei Stufen aufteilen. Die Elemente Shopfloormanagement und Grundfertigkeiten dienen auf der ersten Stufe der Stabilisierung der Produktionsumgebung. Das Element der Standardarbeit gilt als Verbindungsstück zur zweiten Stufe. Die Prinzipien der Wertstromausrichtung, des Qualitätsmanagements und des Anlagenmanagements (TPM) prägen die kontinuierliche Verbesserung (Kaizen) auf der zweiten Stufe des GRIMME Produktionssystems.",
      image: photo01,
    },
    shopFloorManagement:{
      title: "SHOPFLOORMANAGEMENT",
      text1:
        "Der Begriff Shopfloormanagement setzt sich aus den Wörtern „Shopfloor“ (Werkstatt bzw. Hallenboden) und „Management“ (Führung bzw. Steuerung) zusammen. Ziel dieses Elements ist es, auftretende Probleme direkt am Entstehenungsort, nämlich auf dem Shopfloor, nachhaltig abzustellen. Elementar hierfür ist die Führung direkt am Ort der Wertschöpfung.",
      text2:
        "Damit auftretende Probleme von den verantwortlichen Personen frühzeitig entdeckt, systematisch erfasst und gelöst werden können, wurden die vier Instrumente des Shopfloormanagements etabliert: Regelkommunikation, Abweichungsvisualisierung durch 5S, Problemlösungskompetenz am Ort der Entstehung sowie Beschreibungen der Rollen von Werkern und Führungskräften.",
      image: photo02,
    },
    basicSkills:{
      title: "GRUNDFERTIGKEITEN",
      text1:
        "Die Vermittlung der Grundfertigkeiten dient der Stabilisierung der alltäglichen Produktionsschritte und erfolgt durch Schulungen der Mitarbeiter. So erhalten diese die für ihre Aufgaben benötigten Grundkenntnisse und Fähigkeiten. Dies geschieht unter anderem in unseren Trainingszentren für Montagetätigkeiten, Schweißtätigkeiten und die Anwendung von Handmessmitteln, aber auch durch Führungskräfteschulungen auf allen Leitungsebenen.",
      text2:
        "Die Mitarbeiter werden darin geschult standardisiert vorzugehen und erhalten dabei eine kompakte Vermittlung des Basiswissens für die trainierten Arbeitsprozesse. Außerdem wird allen Mitarbeitern das GRIMME Produktionssystem GRIPS in den Trainingszentren vermittelt. Durch die erlernten Fähigkeiten und das angeeignete Wissen wird gerade für neue Mitarbeiter so der Einstieg und die Tätigkeit in ihrem Bereich erleichtert.",
      image: photo03,
    },
    standardWork:{
      title: "STANDARDARBEIT",
      text1:
        "Mithilfe der Standardarbeit stellen wir bei GRIMME die Reproduzierbarkeit von Arbeitsergebnissen und eine gleichbleibend hohe Produktqualität sicher. Um diese Nachhaltigkeit in der Produktion zu gewährleisten, sind eine gleichbleibende Vorgehensweise und genaue Arbeitsanweisungen notwendig.",
      text2:
        "Besonders wichtig für das Prinzip der Standardarbeit ist die Dokumentation der Standards sowie die ständige Anpassung und Verbesserung der standardisierten Prozesse. Dies erfolgt in einem regelmäßigen Kreislauf, bei dem der festgelegte Standard immer wieder überprüft und optimiert wird. Instrumente der Standardarbeit bei GRIMME sind beispielsweise die Generelle Montageanweisungen, festgelegte Arbeitsschritte sowie technische Vorgaben oder Verfahrensanweisungen.",
      image: photo04,
    },
    valueStreamAlignment:{
      title: "WERTSTROMAUSRICHTUNG",
      text1:
        "Die Wertstromausrichtung setzt den Fokus gezielt auf die rein wertschöpfenden Tätigkeiten in Arbeitsabläufen, welche der direkten Erhöhung des Kundennutzens dienen.",
      text2:
        "Ziel der Wertstromausrichtung ist es, jeden Arbeitsgang auf die Wertschöpfung zu konzentrieren. Dies bedeutet nicht die Erhöhung der wertschöpfenden Tätigkeiten, sondern die Reduzierung der nicht wertschöpfenden und verschwendenden Tätigkeiten, um mehr Kapazitäten für Wertschöpfung zu generieren. Bei GRIMME gelingt dies beispielsweise durch Instrumente wie eine mehrstufige Kommissionierung oder durch den Einsatz von speziellen Analysemethoden.",
      image: photo05,
    },
    qualityManagement:{
      title: "QUALITÄTSMANAGEMENT",
      text1:
        "Das Thema Qualitätsmanagement erstreckt sich sowohl über die Maschinenproduktion als auch über sämtliche vorgelagerte Tätigkeiten. Es werden Methoden angewandt, um Mängel zu eliminieren und so im Fertigungsprozess stets eine hohe Qualität zu erzeugen als auch sicherzustellen.",
      text2:
        "Die Qualitätssicherung bei GRIMME ist ein wesentlicher Gestalter der Kundenzufriedenheit und bedient sich dafür verschiedener Instrumente, wie der Qualitäts-Tore, der sogenannten Masterkontrollboards und des Qualitäts-Raumes.",
      image: photo06,
    },
    plantManagement:{
      title: "ANLAGENMANAGEMENT",
      text1:
        "Im Fokus des Anlagenmanagements liegt die Etablierung des Prinzips der autonomen Instandhaltung kombiniert mit geplanter und zustandsorientierter Instandhaltung.",
      text2:
        "Dies führt zu geringeren Maschinenausfällen, weshalb eine zuverlässigere Planung der Durchlaufzeiten erfolgen kann. Folglich wird eine maximale Liefertermintreue für Maschinen und Ersatzteile sichergestellt. Instrumente des Anlagenmanagements bei GRIMME sind eine umfangreiche Dokumentation und detaillierte Tätigkeitsbeschreibungen.",
      image: photo07,
    },
  },
  informationText: {
    main:[
      {
        title: "Shopfloormanagement",
        text:
          "Durch Shopfloormanagement wird Transparenz in den Arbeitsbereichen geschaffen und eine durchgängige Kommunikationsstruktur gewährleistet.",
        align: "right",
        image: photo20,
        ID: uuidv4(),
        more: "Mehr Erfahren",
        path: paths.shopFloorManagement,
      },
      {
        title: "Grundfertigkeiten",
        text:
          "Im Element Grundfertigkeiten wird sichergestellt, dass alle neuen und bestehenden Mitarbeiter über die GRIMME- spezifischen Grundfertigkeiten verfügen. Ziel dabei ist es, die hohen Qualitätsanspruch jederzeit zu gewährleisten.",
        align: "left",
        image: photo30,
        ID: uuidv4(),
        more: "Mehr Erfahren",
        path: paths.basicSkills,
      },
      {
        title: "Standardarbeit",
        text:
          "Die Standardisierung der Arbeitsweisen und Arbeitsplätze ist die Basis und der zentrale Ausgangspunkt für Optimierung.",
        align: "right",
        image: photo40,
        ID: uuidv4(),
        more: "Mehr Erfahren",
        path: paths.standardWork,
      },
      {
        title: "Wertstromausrichtung",
        text:
          "Zu einer ganzheitlichen Wertstromausrichtung gehören stabile Arbeitssysteme und verschwendungsfreie Arbeitsabläufe.",
        align: "left",
        image: photo50,
        ID: uuidv4(),
        more: "Mehr Erfahren",
        path: paths.valueStreamAlignment,
      },
      {
        title: "Qualitätsmanagement",
        text:
          "Das Qualitätsmanagement ist essentiell für die Qualität der Zwischen- und Endprodukte und dient als wesentlicher Gestalter der Kundenzufriedenheit.",
        align: "right",
        image: photo60,
        ID: uuidv4(),
        more: "Mehr Erfahren",
        path: paths.qualityManagement,
      },
      {
        title: "Anlagenmanagement (TPM)",
        text:
          "Mit Hilfe von Anlagenmanagement wird der Fokus auf die Reduzierung der Ausfallzeiten gerichtet und stellt eine zuverlässige Verfügbarkeit der technischen Anlagen sicher.",
        align: "left",
        image: photo70,
        ID: uuidv4(),
        more: "Mehr Erfahren",
        path: paths.plantManagement,
      },
    ],
    shopFloorManagement:[
      {
        title: "Regelkommunikation",
        text:
          "Der tägliche und vor allem strukturierte Austausch an den Kommunikationswänden findet in allen Produktionsbereichen sowie auf allen Hierarchieebenen statt. Dabei werden aktuelle Kennzahlen durchgesprochen und bei Bedarf notwendige Maßnahmen abgeleitet.",
        align: "right",
        image: photo21,
        ID: uuidv4(),
      },
      {
        title: "5 S",
        text:
          "5S ist eine Methode zur Standardisierung der Arbeitsplätze und Arbeitsweisen mit dem Ziel, Abweichungen vom Sollzustand auf einem Blick erkennen zu können. Nach der Umsetzung der fünf Schritte Sortieren, Setzen, Säubern, Standardisieren sowie Selbstdisziplin, kann direkt erkannt werden, ob die richtigen Arbeitsmittel in der richtigen Menge am richtigen Ort vorhanden sind.",
        align: "left",
        image: photo22,
        ID: uuidv4(),
      },
      {
        title: "Problemlösungskompetenz",
        text:
          "Auftretende Probleme werden direkt vor Ort mithilfe eines Problemlösungsflipcharts in systematischen Schritten nachhaltig gelöst. Unterstützung bekommt der geschulte Bearbeiter durch Erklärungen und Hilfestellungen aus der zugehörigen Broschüre.",
        align: "right",
        image: photo23,
        ID: uuidv4(),
      },
      {
        title: "Rollenbeschreibungen",
        text:
          "Für das erfolgreiche Zusammenspiel der Arbeitsvorgänge ist es wichtig, dass es klare Rollen mit eindeutig formulierten Verantwortlichkeiten und exakten Abgrenzungen zwischen den Führungskräften gibt.",
        align: "left",
        image: photo24,
        ID: uuidv4(),
      },
    ],
    basicSkills:[
      {
        title: "Trainingszentrum Montage",
        text:
          "Neben den Basisfertigkeiten, die nicht maschinen- oder teilespezifisch sind, werden den Mitarbeitern elementare und arbeitsplatzspezifische Fertigkeiten vermittelt. Dadurch wird ein gleichbleibendes, qualitativ hochwertiges Montageergebnis erzielt.",
        align: "right",
        image: photo31,
        ID: uuidv4(),
      },
      {
        title: "Trainingszentrum Schweißen",
        text:
          "Die Mitarbeiter werden über ihre fachliche Ausbildung hinaus zusätzlich hinsichtlich GRIMME-spezifischen Anforderungen geschult. Hierzu zählen beispielsweise der Umgang mit Schweißvorrichtungen, das Identifizieren von Schweißnahtfehlern sowie verschiedene Geschicklichkeitsübungen.",
        align: "left",
        image: photo32,
        ID: uuidv4(),
      },
      {
        title: "Trainingszentrum Handmessmittel",
        text:
          "In den täglichen Fertigungsprozessen findet die Beurteilung der Artikel unter anderem mithilfe der Handmessmittel statt. Dazu werden die Mitarbeiter im Hinblick auf Wahl und korrekte Anwendung der richtigen Handmessmittel geschult. Ergänzend werden theoretische Hintergründe und Prinzipien vermittelt.",
        align: "right",
        image: photo33,
        ID: uuidv4(),
      },
      {
        title: "Führungskräfteschulungen",
        text:
          "Die Schulungen sind ein essentieller Baustein der Personalentwicklung. Unsere Führungskräfte werden hinsichtlich des GRIMME Produktionssystems GRIPS geschult und erhalten Weiterbildungsmaßnahmen im Bereich der Führungskompetenzen.",
        align: "left",
        image: photo34,
        ID: uuidv4(),
      },
    ],
    standardWork:[
      {
        title: "Generelle Montageanweisung",
        text:
          "In der Generellen Montageanweisung sind unternehmensweite Montagestandards festgelegt, um stets eine gleichbleibende Qualität sicherzustellen.",
        align: "right",
        image: photo41,
        ID: uuidv4(),
      },
      {
        title: "Festgelegte Arbeitsschritte in den Montagelinien",
        text:
          "Durch die festgelegten Arbeitsschritte werden alle Artikel an den Maschinen in der gleichen Reihenfolge und mit der selben Vorgehensweise montiert. So können Arbeitsabläufe besser geplant werden.",
        align: "left",
        image: photo42,
        ID: uuidv4(),
      },
      {
        title: "Prozesse und Verfahrensanweisungen",
        text:
          "Klare Prozesse führen zu einem strukturierten Miteinander, sodass alle Mitarbeiter mit geordneten Voraussetzungen ihre Arbeit verrichten können.",
        align: "right",
        image: photo43,
        ID: uuidv4(),
      },
    ],
    valueStreamAlignment:[
      {
        title: "Materialbereitstellungszone (MBZ)",
        text:
          "In der MBZ werden sogenannte Setwagen produkt- und arbeitsplatzspezifisch für die Montagelinie bestückt, sodass den Mitarbeitern die richtigen Artikel zur richtigen Zeit in der richtigen Menge am richtigen Ort zur Verfügung stehen.",
        align: "right",
        image: photo51,
        ID: uuidv4(),
      },
      {
        title: "Analysemethoden",
        text:
          "Zur Identifizierung von Verschwendungen werden Analysemethoden wie das „Spaghetti-Diagramm“ oder die sogenannte Material- and Information Flow Analysis (M&I) eingesetzt.",
        align: "left",
        image: photo52,
        ID: uuidv4(),
      },
    ],
    qualityManagement:[
      {
        title: "Qualitäts-Tor",
        text:
          "Der Einsatz der Qualitäts-Tore dient der Absicherung unserer Maschinenqualität, indem Sollvorgaben bereits frühestmöglich anhand baureihenspezifischer Pläne im Prozess abgeprüft werden.",
        align: "right",
        image: photo61,
        ID: uuidv4(),
      },
      {
        title: "Masterkontrollboard",
        text:
          "Das Masterkontrollboard dient als visuelles Hilfsmittel zur strukturierten Abarbeitung von Qualitätsmängeln und unterstützt die wesentlichen Phasen der nachhaltigen Problemlösung.",
        align: "left",
        image: photo62,
        ID: uuidv4(),
      },
      {
        title: "Qualitäts-Raum",
        text:
          "Der Qualitäts-Raum ist eine Methode zur Verbesserung der Produktqualität auf Basis von Rückmeldungen aus dem Feld und damit einhergehend der nachhaltigen Steigerung der Kundenzufriedenheit.",
        align: "right",
        image: photo63,
        ID: uuidv4(),
      },
    ],
    plantManagement:[
      {
        title: "Dokumentation",
        text:
          "Mithilfe von Wartungsübersichten und –checklisten kann der Maschinenbediener regelmäßige Wartungstätigkeiten eigenständig durchführen (Autonome Instandhaltung)",
        align: "right",
        image: photo71,
        ID: uuidv4(),
      },
      {
        title: "Tätigkeitsbeschreibungsblatt",
        text:
          "Anhand des bebilderten Tätigkeitsbeschreibungsblattes wird der Mitarbeiter schrittweise mit Erläuterungen durch die Wartungstätigkeiten geführt.",
        align: "left",
        image: photo72,
        ID: uuidv4(),
      },
    ],
  },
  contactText: {
    default: "Kontakt",
    success: "Danke für Ihre Nachricht",
    error: "Etwas ist schief gelaufen",
    name: "Name",
    email: "E-mail",
    phone: "Telefonnummer",
    message: "Nachricht",
    consent: "Ich stimme der Datenschutzvereinbarung zu",
    consent1: "Ich stimme der ",
    consent2: "Datenschutzvereinbarung",
    consent3: " zu",
    submit: "Absenden",
    errors:{
      name: "Bitte geben Sie Ihren Namen ein",
      email: "Bitte geben Sie eine gültige E-Mail ein",
      phone: "Bitte geben Sie eine gültige Telefonnummer ein",
      message: "Bitte schreiben Sie eine Nachricht",
      consent: "Bitte stimmen Sie unserer Datenschutzvereinbarung zu.",
    },
    contactID: contactID,
  },
  footerText: {
    imprint: "Impressum",
    imprintHref: "https://www.grimme.com/de/page/impressum",
    data: "Datenschuntz",
    dataProtectionHref: "https://www.grimme.com/de/page/datenschutz",
  },
};

export default dataDE;
