function setCookie(cookieName, cookieValue, cookieExp) {
  if (typeof window !== "undefined") {
    const d = new Date();
    d.setTime(d.getTime() + cookieExp * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${cookieName}=${cookieValue};${expires};path=/`;
  }
}

export default setCookie;
