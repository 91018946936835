const paths = {
  main: "/",
  shopFloorManagement: "/shop-floor-management",
  basicSkills: "/basic-skills",
  standardWork: "/standard-work",
  valueStreamAlignment: "/value-stream-alignment",
  qualityManagement: "/quality-management",
  plantManagement: "/plant-management",
};

export default paths;
