import React from "react";

import "./header.css";

function LogoType({ img, isDrawerOpen }) {
  return (
    <div
      className={`${"logotype"} ${
        isDrawerOpen && window.innerWidth <= 600 ? "movedAway" : ""
      }`}
    >
      <a href={"https://www.grimme.com/de"}>
        <div className="logoimgdiv">
          <img src={img} alt={"GRIMME"} title="GRIMME" />
        </div>
      </a>
    </div>
  );
}

export default LogoType;
