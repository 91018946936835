import validate from "validate.js";

var constraints = {
  name: {
    presence: { allowEmpty: false },
  },
  phone: {
    format: {
      pattern:
        "([+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[0-9]*)",
      message: " number is not valid",
    },
  },
  email: {
    email: true,
  },
  message: {
    presence: { allowEmpty: false },
  },
  consent: {
    consent: true,
  },
};

validate.validators.consent = (value, options, key, attributes) => {
  if (!value) {
    return "for data privacy must be given";
  }
};

function validation(name, phone, email, message, consent) {
  const validationResult = validate(
    {
      name: name,
      phone: phone,
      email: email,
      message: message,
      consent: consent,
    },
    constraints
  );
  if (validationResult === undefined) {
    return [true];
  } else {
    return [false, validationResult];
  }
}

export default validation;
